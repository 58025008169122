<template>
    <div class="work">
        <el-radio-group v-model="activeName" fill="#31A458">
            <el-radio-button label="work">插花作品</el-radio-button>
            <el-radio-button label="collect">常用素材</el-radio-button>
        </el-radio-group>
        <el-button
            type="success"
            style="width: 400px; float: right; margin-rigth: 100px"
            @click="toEdit"
        >
            进入插花练习
        </el-button>
        <div v-if="activeName === 'work'">
            <div v-if="!work.loading && !work.list.length" class="no_data">
                <i class="iconfont icon-queshengye_zanwushuju"></i>
                <div>暂无数据</div>
            </div>
            <div v-if="!work.loading && work.list.length" class="data_list">
                <div v-for="(item, index) in work.list" :key="index">
                    <div
                        :style="item.coverImage | imgFormat(true)"
                        @click="toDetail(item)"
                    >
                        <div
                            class="threeflag"
                            v-if="item.editorType === 1 || !item.editorType"
                        >
                            2D
                        </div>
                        <div class="threeflag" v-if="item.editorType === 2">
                            3D
                        </div>
                        <div class="threeflag" v-if="item.editorType === 3">
                            VR
                        </div>
                        <div
                            class="ls_flexalign_center opt_part"
                            @click.stop=""
                        >
                            <div class="ls_flex_center" @click="share(item)">
                                <i class="iconfont icon-fenxiang" />
                                分享
                            </div>
                            <div class="ls_flex_center" @click="getInfo(item)">
                                <i class="iconfont icon-bianji" />
                                信息
                            </div>
                            <div
                                class="ls_flex_center"
                                @click="viewMaterials(item)"
                            >
                                <i class="iconfont icon-tiezi" />
                                用料
                            </div>
                            <div class="ls_flex_center" @click="del(item)">
                                <i class="iconfont icon-shanchu" />
                                删除
                            </div>
                        </div>
                    </div>
                    <div>{{ item.name }}</div>
                </div>
            </div>
            <div style="text-align: center; margin-top: 10px">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="work.total"
                    :current-page.sync="work.params.pageIndex"
                    :page-size="work.params.pageSize"
                    @current-change="getWorkList"
                ></el-pagination>
            </div>
        </div>
        <div v-if="activeName === 'collect'">
            <div
                v-if="!collect.loading && !collect.list.length"
                class="no_data"
            >
                <i class="iconfont icon-queshengye_zanwushuju"></i>
                <div>暂无数据</div>
            </div>
            <div
                v-if="!collect.loading && collect.list.length"
                class="data_list collect_list"
            >
                <div v-for="(item, index) in collect.list" :key="index">
                    <div :style="item.coverImage | imgFormat(true)">
                        <div
                            class="ls_flexalign_center opt_part"
                            @click.stop=""
                        >
                            <div class="ls_flex_center" @click="cancel(item)">
                                <i class="iconfont icon-shoucang1" />
                                取消收藏
                            </div>
                        </div>
                    </div>
                    <div>{{ item.name }}</div>
                </div>
            </div>
            <div style="text-align: center; margin-top: 10px">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="collect.total"
                    :current-page.sync="collect.params.pageIndex"
                    :page-size="collect.params.pageSize"
                    @current-change="getCollectList"
                ></el-pagination>
            </div>
        </div>
        <el-dialog title="修改作品信息" :visible.sync="infoShow" width="600px">
            <el-form label-width="80px" v-if="infoShow">
                <el-form-item label="作品名称">
                    <el-input v-model="info.name"></el-input>
                </el-form-item>

                <el-form-item label="">
                    <el-button type="primary" @click="confirm">确定</el-button>
                    <el-button @click="infoShow = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="用料" :visible.sync="materialsShow" width="400px">
            <div v-for="(item, index) in materials" :key="index">
                <span class="lh26 fz14 cf21 mr10">⬤</span>
                <span class="lh26 fz14">
                    {{ item.materialName }} x{{ item.count }}
                </span>
            </div>
        </el-dialog>

        <el-dialog title="分享" :visible.sync="shareShow" width="400px">
            <img class="share_pop" :src="imgUrl" />
            <div style="text-align: center; margin-top: 25px; font-size: 18px">
                微信扫一扫，三维作品发送手机
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Vue from "vue";
export default {
    data() {
        return {
            activeName: "work",
            work: {
                total: 0,
                params: {
                    pageIndex: 1,
                    pageSize: 8,
                },
                loading: true,
                list: [],
            },
            collect: {
                total: 0,
                params: {
                    type: "FLOWER",
                    pageIndex: 1,
                    pageSize: 8,
                },
                loading: true,
                list: [],
                number: {
                    flower: 0,
                    cover: 0,
                },
            },
            draft: {
                total: 0,
                params: {
                    pageIndex: 1,
                    pageSize: 8,
                },
                loading: true,
                list: [],
            },
            infoShow: false,
            shareShow: false,
            materialsShow: false,

            imgUrl: "",
            info: "",
            materials: [],
        };
    },
    mounted() {
        this.getWorkList();
        this.getCollectList();
    },
    methods: {
        //获取作品数据
        getWorkList() {
            this.$ajax
                .post("/product/queryList", this.work.params)
                .then(res => {
                    this.work.loading = false;
                    this.work.total = res.total;
                    this.work.list = res.list;
                });
        },
        toEdit() {
            window.open(
                this.$editAddr +
                    `?authToken=${localStorage.getItem("authToken")}`
            );
        },
        //删除
        del(obj) {
            this.$confirm("删除后不可恢复，确定是否删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
            }).then(() => {
                this.$ajax
                    .get("/product/delete", {
                        params: {
                            id: obj.id,
                        },
                    })
                    .then(() => {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                        });
                        this.getWorkList();
                    });
            });
        },
        cancel(item) {
            this.$confirm("确定取消收藏?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
            }).then(() => {
                this.$ajax
                    .get("/material/deleteLikeMaterial", {
                        params: {
                            materialId: item.id,
                        },
                    })
                    .then(res => {
                        this.$message({
                            message: "取消成功",
                            type: "success",
                        });
                        this.getCollectList();
                    });
            });
        },
        //去详情
        toDetail(item) {
            window.open(
                this.$editAddr +
                    "?mode=edit&productId=" +
                    item.id +
                    `&authToken=${localStorage.getItem("authToken")}`
            );
        },
        //获取收藏数据
        getCollectList() {
            this.$ajax
                .post("/material/queryLikeMaterialList", this.collect.params)
                .then(res => {
                    console.log(res);
                    this.collect.loading = false;
                    this.collect.total = res.total;
                    this.collect.list = res.list;
                });
        },

        share(item) {
            this.$ajax
                .get("/product/createShareCode", {
                    params: { id: item.id },
                })
                .then(res => {
                    this.imgUrl = Vue.filter("imgFormat")(res.data);
                    this.shareShow = true;
                });
        },
        //确定修改信息
        confirm() {
            if (!this.info.name) {
                this.$message({
                    message: "请输入作品名称",
                    type: "warning",
                });
                return;
            }
            console.log(this.info);
            this.$ajax
                .get("/product/updateNameById", {
                    params: {
                        id: this.info.id,
                        name: this.info.name,
                    },
                })
                .then(response => {
                    this.$message({
                        message: "设置成功",
                        type: "success",
                    });
                    this.infoShow = false;
                    this.getWorkList();
                });
        },
        //获取信息
        getInfo(item) {
            this.info = JSON.parse(JSON.stringify(item));
            this.infoShow = true;
        },
        viewMaterials(item) {
            console.log(item);
            this.materials = JSON.parse(JSON.stringify(item.useMaterials));
            this.materialsShow = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.threeflag {
    font-size: 16px;
    color: #999;
    position: absolute;
    right: 20px;
    top: 20px;
}
.share_pop {
    width: 360px;
    height: 360px;
}

.opt_part {
    height: 35px;
    background: #000000b7;
    color: #fff;
    font-size: 12px;
    border-radius: 0 0 10px 10px;
    position: absolute;
    z-index: 999;
    width: 100%;
    bottom: 30px;
    opacity: 0;
    transition: all 0.3s;

    > div {
        flex: 1;
        height: 100%;

        + div {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 1px;
                height: 15px;
                background: #fff;
                top: 10px;
                left: 0px;
            }
        }
    }

    i {
        margin-right: 5px;
    }
}

.work {
    padding: 20px 20px 20px 0;
}

.no_data {
    text-align: center;
    color: #999;
    margin-top: 50px;

    i {
        font-size: 150px;
    }

    div {
        margin-top: 10px;
    }
}

.data_list {
    margin: 0 auto;
    padding-top: 40px;
    width: 1080px;
    overflow: hidden;

    > div {
        float: left;
        margin: 0 0 30px 20px;
        position: relative;

        &:nth-child(4n + 1) {
            margin-left: 0;
        }

        > div {
            &:first-child {
                height: 250px;
                width: 250px;
                background: #fff;
                border-radius: 10px;
                cursor: pointer;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            text-align: center;

            &:last-child {
                margin-top: 15px;
            }
        }

        &:hover .opt_part {
            opacity: 1;
        }
    }
}

.collect_list {
    > div {
        > div {
            &:first-child {
                background-size: 150px 150px;
            }
        }
    }
}

.cf21 {
    color: #6e9f21;
}
</style>
